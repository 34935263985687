/* eslint-disable no-restricted-properties */
import _startCase from 'lodash/startCase';
import {
  maximumAssetDisplayNumber,
  maximumAssetNumber,
  minimumAssetDisplayNumber,
  minimumAssetNumber,
  zeroThresholdDisplayNumber,
  zeroThresholdNumber,
} from '../../../data/app-config/appDetails';
import { checkIfExponential, getBigNumberString } from './numberUtilities';

/**
 * 'Safer' String.toUpperCase() for regex calls
 */
export const upperCase = (str) => str.toUpperCase();

/**
 * 'Safer' String.toLowerCase() for regex calls
 */
export const lowerCase = (str) => str.toLowerCase();

/**
 * Add space between camelCase text.
 */
export const unCamelCase = (str) => {
  let newStr = str;
  newStr = newStr.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
  newStr = newStr.toLowerCase();
  return newStr;
};

/**
 * Add space between camelCase text.
 */
export const unSnakeCase = (str) => {
  let newStr = str;
  newStr = newStr.replace(/_/g, ' ');
  newStr = newStr.toLowerCase();
  return newStr;
};

/**
 * Transform anything to snake case
 */
export const toSnakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

/**
 * UPPERCASE first char of each word.
 */
export const properCase = (str) =>
  str.toLowerCase().replace(/^\w|\s\w/g, upperCase);

/**
 * Produce UPPERCASE initials of word(s) as single string, max two chars.
 */
export const createInitials = (str) => {
  const names = str.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

/**
 * Remove non-word chars.
 */
export const removeNonWord = (str) =>
  str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '');

/**
 * Replaces all accented chars with regular ones
 */
export const replaceAccents = (str) => {
  let newStr = str;
  // verifies if the String has accents and replace them
  if (newStr.search(/[\xC0-\xFF]/g) > -1) {
    newStr = str
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xDE]/g, 'P')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFE]/g, 'p')
      .replace(/[\xFD\xFF]/g, 'y');
  }

  return newStr;
};

/**
 * Convert string to camelCase text.
 */
export const camelCase = (str) => {
  let newStr = str;
  newStr = replaceAccents(newStr);
  newStr = removeNonWord(newStr)
    .replace(/-/g, ' ') // convert all hyphens to spaces
    .replace(/\s[a-z]/g, upperCase) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, '') // remove spaces
    .replace(/^[A-Z]/g, lowerCase); // convert first char to lowercase
  return newStr;
};

/**
 * Convert number to formatted number string.
 */
export const numberString = (num) => {
  const newStr = num.toLocaleString('en-US');
  return newStr;
};

/**
 * Convert number to formatted currency string, with optional internationalization.
 */
export const currencyString = (value, currency, options) => {
  let amount = value;

  if (typeof value === 'string') {
    amount = Number(amount);
  }

  const optObj = {
    currency: currency || 'USD',
    style: 'currency',
    ...options,
  };
  const newStr = amount.toLocaleString('en-US', optObj);

  return newStr;
};

/**
 * Get the file extension on end of string
 */
export const getExtensionFromFilename = (filename) =>
  (
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
    filename
  ).toLowerCase();

/**
 * Get the file extension on end of string
 */
export const trimString = (str) => {
  if (typeof str === 'string') return str.trim();
  return str;
};

/**
 * truncate a string in the middle passing in the amount of characters
 * that are allowed to be shown
 */
export const truncateMiddleString = (string, maxLength) => {
  if (!string) return string;
  if (maxLength < 1) return string;
  if (string.length <= maxLength) return string;
  if (maxLength === 1) return `${string.substring(0, 1)}...`;

  const midpoint = Math.ceil(string.length / 2);
  const toremove = string.length - maxLength;
  const lstrip = Math.ceil(toremove / 2);
  const rstrip = toremove - lstrip;
  return `${string.substring(0, midpoint - lstrip)}.....${string.substring(
    midpoint + rstrip,
  )}`;
};

/**
 * to provide consistent number formats across app
 *
 * @param {String | Number} amt - the number to be formatted, can be a string or number JS type
 * @param {Object} [options={}] - options object for amount string formatting and output
 * @param {Boolean} [options.allowNull=false] - if true, the formatted value returned can be empty string (if applicable)
 * @param {Boolean} [options.allowZero=false] - if true, 0 values will be returned in currency notation (0.00) instead of 0
 * @param {Number} [options.decimals=8] - the amount of decimals the formatted number should display (if applicable)
 * @param {Number} [options.minimumFractionDigits=2] - the lowest amount of decimals that can be shown in formatted value
 * @param {String} [options.symbol=null] - adds a suffix to display that contains symbol ( 1.439 BTC)
 * @param {Boolean} [options.usePrefix=false] - if true, positive values will be preceded by a '+'
 * @param {Boolean} [options.useSign=false] - if true, negative values will display as normal ('-' in front)
 * @param {Boolean} [options.useAmountDecimals=false] -  whether to use the amt decimal length to decide decimals places
 * @param {Boolean} [options.useRawFormat=false] - whether to apply toLocaleString or not
 * @param {Boolean} [options.useZeroThreshold=false] - whether 0 should be displayed for extremely small amounts
 * @param {Boolean} [options.input=false] - where 0 values should not be formatted since text is likely to be updated
 * @returns {String} - the formatted amount value
 */
export const amountString = (amt, options = {}) => {
  const {
    allowNull = false,
    allowZero = false,
    decimals = 8,
    minimumFractionDigits = 2,
    symbol = null,
    useMinMax = true,
    useNA = false,
    usePrefix = false,
    useSign = true,
    useAmountDecimals = false,
    useRawFormat = false,
    useZeroThreshold = true,
    input = false,
  } = options;

  const parsedAmount = parseFloat(amt);
  const absAmount = Math.abs(parsedAmount);
  const unsupportedValue = amt === 'N/A' || Number.isNaN(parsedAmount);
  const displayMinMax = useMinMax && !useRawFormat;
  const displayZero = useMinMax && useZeroThreshold && !useRawFormat;

  const toFixedDecimals = absAmount > 0 && absAmount < 1 ? decimals : 4;

  const isExponential = checkIfExponential(parsedAmount);

  const tinyAmount = absAmount < minimumAssetNumber;
  const bigAmount = absAmount > maximumAssetNumber;
  const considerZero = absAmount <= zeroThresholdNumber;

  const isNegative = parsedAmount < 0;
  const signStr = isNegative && useSign ? '-' : `${usePrefix ? '+' : ''}`;
  const symbolStr = symbol ? ` ${symbol}` : '';

  // !amt check can fail if number passed is 0 so also check to make sure 0 is passed
  if ((allowNull && unsupportedValue) || (parsedAmount !== 0 && !amt))
    return '';
  if ((unsupportedValue || parsedAmount === 0) && input) return amt;
  if ((unsupportedValue || parsedAmount === 0) && (!useNA || allowZero))
    return `0.00${symbolStr}`;
  if ((unsupportedValue || parsedAmount === 0) && useNA) return 'N/A';

  // use approx since full decimals range can be cut off
  if (absAmount === minimumAssetNumber && displayMinMax) {
    return `≈ ${signStr}${minimumAssetDisplayNumber}${symbolStr}`;
  }
  if (absAmount === maximumAssetNumber && displayMinMax) {
    return `≈ ${signStr}${maximumAssetDisplayNumber}${symbolStr}`;
  }
  if (considerZero && displayZero && displayMinMax) {
    return `${zeroThresholdDisplayNumber}${symbolStr}`;
  }
  // ex: 0.000000001
  if (tinyAmount && displayMinMax) {
    return `< ${signStr}${minimumAssetDisplayNumber}${symbolStr}`;
  }
  // ex: 10000000000
  if (bigAmount && displayMinMax) {
    return `${
      isNegative ? '<' : '>'
    } ${signStr}${maximumAssetDisplayNumber}${symbolStr}`;
  }

  if (isExponential) {
    // exponential notation needs to be converted to string
    const convertedExponentialString = getBigNumberString(absAmount, {
      decimals: toFixedDecimals,
      useAmountDecimals,
      useRawFormat,
    });
    return `${signStr}${convertedExponentialString}${symbolStr}`;
  }
  // number was deemed to be in valid display range as is
  if (!useRawFormat) {
    return `${signStr}${absAmount.toLocaleString('en-US', {
      minimumFractionDigits,
      maximumFractionDigits: toFixedDecimals,
    })}${symbolStr}`;
  }
  if (useRawFormat) {
    return parsedAmount;
  }
  // fallback and return input param
  return amt;
};

export const formatDisplayAmount = (val) =>
  amountString(val, {
    useAmountDecimals: true,
    useMinMax: false,
  });

export const normalizeCurrencyString = (value) => {
  if (!value) return value;

  if (typeof value === 'string') {
    const newValArr = value.split(' (');
    if (newValArr.length > 1) {
      return `${newValArr[0].toUpperCase()} (${properCase(newValArr[1])}`;
    }
    return value.toUpperCase();
  }
  return value;
};

export const titleCase = (value) => {
  const titleCaseStr = _startCase(value);
  return titleCaseStr;
};
