// absolute imports
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Field } from 'react-final-form';

// relative imports
import FormReviewField from '../final-form-field-wrappers/FormReviewField';

// STYLES FOR FORM
const useStyles = makeStyles()((theme, { maxWidth }) => ({
  reviewContainer: {
    overflowX: 'scroll',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: maxWidth || '85vw',
  },
  reviewItem: {
    marginBottom: theme.spacing(0.5),
  },
}));

/**
 * Used when you would like to display fields that the user has entered
 * on a previous step without taking too much space on the current step
 * Should only be used within a React Final Form <Form /> component
 */
const FormPreviewValueCard = ({
  formFields,
  maxWidth,
  showDivider,
}) => {
  const { classes } = useStyles({ maxWidth });

  return (
    <Paper className={classes.reviewContainer}>
      {formFields.map((field, index) =>
        field.value && !field.hidden ? (
          <div
            key={field.id}
            className={
              index === formFields.length - 1 ? undefined : classes.reviewItem
            }
          >
            <Field
              name={field.value}
              displayName={field.display}
              nullValue={field.nullValue}
              component={FormReviewField}
              format={field.format}
              hideDivider={!showDivider || index === formFields.length - 1}
            />
          </div>
        ) : null,
      )}
    </Paper>
  );
};

export default FormPreviewValueCard;

FormPreviewValueCard.propTypes = {
  formFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showDivider: PropTypes.bool,
  maxWidth: PropTypes.number,
};

FormPreviewValueCard.defaultProps = {
  showDivider: false,
  maxWidth: undefined,
};
