// absolute imports
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from 'tss-react/mui';

// relative imports
import { useConfig } from '../../../providers/ConfigProvider';
import { useAccount } from '../../../providers/AccountProvider';
import { useAuth0 } from '../../../providers/Auth0Provider';
import useApolloErrorState from '../../../../shared/custom-hooks/useApolloErrorState';
import { CREATE_ACCOUNTING_ACCOUNT } from '../../../../../data/apollo/mutations';
import MultiPageForm from '../../../../shared/custom-components/MultiPageForm';
import {
  creatingAccountVar,
  currentAccountTimezoneVar,
} from '../../../../../data/apollo/cache/reactiveVars';
import { getDbIdFromClientFieldId } from '../../../../../data/app-config/configs/subtypeField-utilities';
import { useDialogState } from '../../../../shared/custom-hooks/useDialogState';

const useStyles = makeStyles()(() => ({
  mainGrid: {
    height: '100%',
  },
}));

const CreateAccountFormWrapper = ({ children, ...SelectFlowProps }) => {
  const { appConfig, accountConfig } = useConfig();
  const { classes } = useStyles();
  const { user, refetchUser } = useAuth0();
  const { currentAccount, selectAccount, refetchAccounts, setHasNoAccount } =
    useAccount();
  const { subtype, subtypeFields } = currentAccount;
  const { updateDialogState } = useDialogState();
  const [createAccountError, clearError] = useApolloErrorState([
    'createAccountingAccount',
  ]);

  // subtype condtions from ENV
  const subtypeRequired = appConfig.accountSubtypeRequired;
  const subtypeSelectionEnabled = !!appConfig.accountSubtypeTag;
  const subtypeDefault = appConfig.accountSubtypeDefault;

  const { goToStep, currentStepIndex, submitStepKey, setStepsLength } =
    SelectFlowProps;

  const filteredSteps = useMemo(
    () =>
      children.filter((child) => {
        switch (child.key) {
          case 'caSubtypeSelect':
            return (
              subtypeRequired && !subtypeDefault && subtypeSelectionEnabled
            );
          case 'caSubtypeFields':
            return (
              subtypeRequired ||
              (subtype && Object.keys(subtypeFields).length > 0)
            );
          default:
            return true;
        }
      }),
    [
      children,
      subtype,
      subtypeDefault,
      subtypeFields,
      subtypeRequired,
      subtypeSelectionEnabled,
    ],
  );

  useEffect(() => {
    if (filteredSteps.length !== children.length) {
      setStepsLength(filteredSteps.length);
    }
  }, [filteredSteps.length, children.length, setStepsLength]);

  // accounts for post-submission page plus arr indexes in MultiPageForm
  const submitStepIndex = filteredSteps
    .map(({ key }) => key)
    .indexOf(submitStepKey);

  // create account mutation
  const [
    createAccount,
    { data: { createAccountingAccount = {} } = {}, loadingCreateAccount },
  ] = useMutation(CREATE_ACCOUNTING_ACCOUNT);

  // submit function for second step
  const handleCreateAccount = (values, callback) => {
    // clear error if submitting
    if (createAccountError) {
      clearError();
    }

    const enteredSubtypeFields = {};
    // assign subtype fields to mutation vars if present
    if (values.subtype) {
      const { subtypeFields: subtypeFieldDefs } =
        accountConfig?.accountSetup || {};
      if (subtypeFieldDefs) {
        subtypeFieldDefs.forEach((fieldId) => {
          const backendId = getDbIdFromClientFieldId(fieldId);
          enteredSubtypeFields[backendId] = values[fieldId];
        });
      }
    }

    const newAccountDetails = {
      name: values.name,
      // deprecated, but designate ach if present on user
      achCustomer: !!user.ach,
      // if creating an account by UI, map this to the values.subtype's config
      accountingAdd: accountConfig.accountSetup?.enableAccountAdd,
      // pass timezone
      timezone: values.timezone,
      // pass subtype config
      subtype: values.subtype,
      subtypeFields: enteredSubtypeFields,
    };

    createAccount({
      variables: {
        ...newAccountDetails,
      },
      onCompleted(res = {}) {
        if (res.createAccountingAccount) {
          // refetch user AND accounts to ensure availableApps are populated if first account
          refetchUser();
          refetchAccounts();
          updateDialogState('pristine', true);
          // send form to next step
          callback();
        }
      },
    });
  };

  // submit function for multi-page form final step
  const handleFinalSubmit = () => {
    selectAccount(createAccountingAccount.id);
    creatingAccountVar(false);
    setHasNoAccount(false);
  };

  return (
    <MultiPageForm
      persist
      initialValues={{
        name: '',
        timezone: currentAccount?.timezone || currentAccountTimezoneVar(),
        subtype: subtypeDefault || subtype?.id || '',
      }}
      formOptions={{
        keepDirtyOnReinitialize: true,
      }}
      formPageOptions={{
        submissions: {
          [submitStepIndex]: handleCreateAccount,
        },
        validators: {
          [submitStepIndex]: () => !createAccountError,
        }
      }}
      submitError={createAccountError}
      formSubmit={handleFinalSubmit}
      goToPage={currentStepIndex}
      setGoToPage={goToStep}
      className={classes.formWrapper}
      createdAccountId={createAccountingAccount.id}
      createAccountError={createAccountError}
      createAccountLoading={loadingCreateAccount}
    >
      {filteredSteps}
    </MultiPageForm>
  );
};

export default CreateAccountFormWrapper;

CreateAccountFormWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]).isRequired,
};
