import {
  getDate,
  guessTimezone,
} from '../../../shared/utilities/dateUtilities';
import {
  appTypeVar,
  currentAccountTaxYearVar,
  currentAccountTimezoneVar,
} from '../../../../data/apollo/cache/reactiveVars';

export const defaultEntryFieldNames = {
  // for which values are required
  context: 'context',
  side: 'side',
  sourceType: 'sourceType',
  // required for all types
  date: 'date',
  time: 'time',
  timezone: 'timezone',
  type: 'type',
  // norm types
  amount: 'amount',
  symbol: 'symbol',
  useDefaultPricing: 'useDefaultPricing',
  taxCategory: 'taxCategory',
  // crypto-crypto types
  orderType: 'orderType',
  toAmount: 'toAmount',
  fromAmount: 'fromAmount',
  toSymbol: 'toSymbol',
  fromSymbol: 'fromSymbol',
  // for norm types & nft
  price: 'price',
  priceCurrency: 'priceCurrency',
  currencyType: 'currencyType',
  // for defi & nft types
  chain: 'chain',
  // optional for all types
  fee: 'fee',
  feeCurrency: 'feeCurrency',
  feeCurrencyContract: 'feeCurrencyContract',
  useFee: 'useFee',
  // order types
  source: 'source',
  selectedSource: 'selectedSource',
  useFiat: 'useFiat',
  id: 'id',
  // nft types
  uniqueId: 'uniqueId',
  displayName: 'displayName',
  contract: 'contract',
  asset: 'asset',
  // defi types
  amount1: 'amount1',
  amount2: 'amount2',
  amount3: 'amount3',
  amount4: 'amount4',
  asset1: 'asset1',
  asset2: 'asset2',
  asset3: 'asset3',
  asset4: 'asset4',
  asset1Contract: 'asset1Contract',
  asset2Contract: 'asset2Contract',
  poolShare: 'poolShare',
  defiSource: 'defiSource',
  unsupported: 'unsupported',
  organizationId: 'organizationId',
  organizationName: 'organizationName',
  organizationAddress: 'organizationAddress',
  // sanity
  sanitySourceId: 'sanitySourceId',
  sanityDataSourceId: 'sanityDataSourceId',
};

export const defaultNestedEntryFieldNames = {
  context: 'manual.entry.context',
  side: 'manual.entry.side',
  sourceType: 'manual.entry.sourceType',
  date: 'manual.entry.date',
  time: 'manual.entry.time',
  timezone: 'manual.entry.timezone',
  type: 'manual.entry.type',
  amount: 'manual.entry.amount',
  symbol: 'manual.entry.symbol',
  useDefaultPricing: 'manual.entry.useDefaultPricing',
  taxCategory: 'manual.entry.taxCategory',
  toAmount: 'manual.entry.toAmount',
  fromAmount: 'manual.entry.fromAmount',
  toSymbol: 'manual.entry.toSymbol',
  fromSymbol: 'manual.entry.fromSymbol',
  orderType: 'manual.entry.orderType',
  currencyType: 'manual.entry.currencyType',
  price: 'manual.entry.price',
  priceCurrency: 'manual.entry.priceCurrency',
  chain: 'manual.entry.chain',
  fee: 'manual.entry.fee',
  feeCurrency: 'manual.entry.feeCurrency',
  feeCurrencyContract: 'manual.entry.feeCurrencyContract',
  useFee: 'manual.entry.useFee',
  source: 'manual.entry.source',
  selectedSource: 'manual.entry.selectedSource',
  useFiat: 'manual.entry.useFiat',
  id: 'manual.entry.id',
  uniqueId: 'manual.entry.uniqueId',
  displayName: 'manual.entry.displayName',
  contract: 'manual.entry.contract',
  asset: 'manual.entry.asset',
  amount1: 'manual.entry.amount1',
  amount2: 'manual.entry.amount2',
  amount3: 'manual.entry.amount3',
  amount4: 'manual.entry.amount4',
  asset1: 'manual.entry.asset1',
  asset2: 'manual.entry.asset2',
  asset3: 'manual.entry.asset3',
  asset4: 'manual.entry.asset4',
  asset1Contract: 'manual.entry.asset1Contract',
  asset2Contract: 'manual.entry.asset2Contract',
  poolShare: 'manual.entry.poolShare',
  defiSource: 'manual.entry.defiSource',
  unsupported: 'manual.entry.unsupported',
  organizationId: 'manual.entry.organizationId',
  organizationName: 'manual.entry.organizationName',
  organizationAddress: 'manual.entry.organizationAddress',
  sanitySourceId: 'manual.entry.sanitySourceId',
  sanityDataSourceId: 'manual.entry.sanityDataSourceId',
};

const defaultValues = {
  context: 'standard',
  side: null,
  sourceType: null,
  date: getDate().startOf('day').toUTC().toString(),
  time: null,
  timezone: currentAccountTimezoneVar() || guessTimezone(),
  type: '',
  amount: '',
  symbol: null,
  useDefaultPricing: 'true',
  taxCategory: '',
  toAmount: '',
  fromAmount: '',
  orderType: 'fiat',
  toSymbol: null,
  fromSymbol: null,
  price: '',
  priceCurrency: '',
  currencyType: 'fiat',
  chain: '',
  fee: '',
  feeCurrency: '',
  feeCurrencyContract: '',
  useFee: false,
  source: null,
  selectedSource: '',
  useFiat: false,
  id: '',
  uniqueId: '',
  displayName: '',
  contract: '',
  asset: '',
  amount1: '',
  amount2: '',
  asset1: '',
  asset2: '',
  asset1Contract: '',
  asset2Contract: '',
  poolShare: '',
  defiSource: '',
  unsupported: false,
  organizationId: null,
  organizationName: '',
  organizationAddress: '',
  sanitySourceId: null,
  sanityDataSourceId: null,
};

export const getDefaultValues = (nestedMap = false) => {
  const fieldNames = nestedMap
    ? defaultNestedEntryFieldNames
    : defaultEntryFieldNames;
    const taxYear = currentAccountTaxYearVar();
    const appType = appTypeVar();

  return {
    [fieldNames.context]: defaultValues.context,
    [fieldNames.side]: defaultValues.side,
    [fieldNames.sourceType]: defaultValues.sourceType,
    [fieldNames.date]:
      appType === 'tax' && taxYear
        ? getDate(`${taxYear}-01-01`, {
            type: 'local',
          }).toString()
        : defaultValues.date,
    [fieldNames.time]: getDate()
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .toUTC()
      .toString(),
    [fieldNames.timezone]: currentAccountTimezoneVar() || guessTimezone(),
    [fieldNames.type]: defaultValues.type,
    [fieldNames.symbol]: defaultValues.symbol,
    [fieldNames.amount]: defaultValues.amount,
    [fieldNames.useDefaultPricing]: defaultValues.useDefaultPricing,
    [fieldNames.taxCategory]: defaultValues.taxCategory,
    [fieldNames.toAmount]: defaultValues.toAmount,
    [fieldNames.fromAmount]: defaultValues.fromAmount,
    [fieldNames.toSymbol]: defaultValues.toSymbol,
    [fieldNames.fromSymbol]: defaultValues.fromSymbol,
    [fieldNames.price]: defaultValues.price,
    [fieldNames.priceCurrency]: defaultValues.priceCurrency,
    [fieldNames.currencyType]: defaultValues.currencyType,
    [fieldNames.chain]: defaultValues.chain,
    [fieldNames.fee]: defaultValues.fee,
    [fieldNames.feeCurrency]: defaultValues.feeCurrency,
    [fieldNames.feeCurrencyContract]: defaultValues.feeCurrencyContract,
    [fieldNames.useFee]: defaultValues.useFee,
    [fieldNames.source]: defaultValues.source,
    [fieldNames.selectedSource]: defaultValues.selectedSource,
    [fieldNames.useFiat]: defaultValues.useFiat,
    [fieldNames.id]: defaultValues.id,
    [fieldNames.uniqueId]: defaultValues.uniqueId,
    [fieldNames.displayName]: defaultValues.displayName,
    [fieldNames.contract]: defaultValues.contract,
    [fieldNames.asset]: defaultValues.asset,
    [fieldNames.amount1]: defaultValues.amount1,
    [fieldNames.amount2]: defaultValues.amount2,
    [fieldNames.amount3]: defaultValues.amount3,
    [fieldNames.amount4]: defaultValues.amount4,
    [fieldNames.asset1]: defaultValues.asset1,
    [fieldNames.asset2]: defaultValues.asset2,
    [fieldNames.asset3]: defaultValues.asset3,
    [fieldNames.asset4]: defaultValues.asset4,
    [fieldNames.asset1Contract]: defaultValues.asset1Contract,
    [fieldNames.asset2Contract]: defaultValues.asset2Contract,
    [fieldNames.poolShare]: defaultValues.poolShare,
    [fieldNames.defiSource]: defaultValues.defiSource,
    [fieldNames.unsupported]: defaultValues.unsupported,
    [fieldNames.organizationName]: defaultValues.organizationName,
    [fieldNames.organizationAddress]: defaultValues.organizationAddress,
    [fieldNames.sanitySourceId]: defaultValues.sanitySourceId,
    [fieldNames.sanityDataSourceId]: defaultValues.sanityDataSourceId,
  };
};
