import {
  manualDefiFormatTypeRef,
  manualDefiTypeRef,
  manualNFTTypeRef,
  manualOrderTypeRef,
  manualTransferTypeRef,
  manualUIStandardCategories,
} from '../manualRef';
import {
  g20CurrencyNames,
  zeroThresholdNumber,
} from '../../../../data/app-config/appDetails';
import { convertToStandardTime, getDate } from '../../../shared/utilities/dateUtilities';
import { getDefaultValues } from './manualFormDefaults';

export const checkInvalidInput = (input) => {
  if ([undefined, null].includes(input)) return true;
  if (typeof input === 'string') return input.trim() === '';
  if (Array.isArray(input)) {
    return input.some(checkInvalidInput);
  }
  return false;
};

export const evaluateZero = (num) => {
  if (parseFloat(num) === 0) {
    return zeroThresholdNumber;
  }
  return num;
};

const deleteManualUIFields = (entry = {}) => {
  delete entry.selectedSource;
  delete entry.time;
  delete entry.useDefaultPricing;
  delete entry.useFiat;
  delete entry.useFee;
  delete entry.newSource;
  delete entry.useNewSource;
  delete entry.context;
  delete entry.timezone;
  delete entry.unsupported;
  delete entry.currencyType;
  delete entry.context;
  delete entry.sourceType;
  delete entry.side;
  delete entry.orderType;
  delete entry.sanitySourceId;
  delete entry.sanityDataSourceId;
};

const deleteDefiFields = (entry = {}) => {
  delete entry.amount1;
  delete entry.amount2;
  delete entry.amount3;
  delete entry.amount4;
  delete entry.asset1;
  delete entry.asset2;
  delete entry.asset3;
  delete entry.asset4;
  delete entry.poolShare;
  delete entry.defiSource;
  delete entry.asset1Contract;
  delete entry.asset2Contract;
  delete entry.feeCurrencyContract;
  if (!manualNFTTypeRef[entry.type]) {
    delete entry.chain;
  }
};

export const deleteNormFields = (entry = {}) => {
  delete entry.amount;
  delete entry.symbol;
  if (checkInvalidInput(entry.taxCategory)) {
    delete entry.taxCategory;
  }
};

const deleteNFTFields = (entry = {}) => {
  delete entry.asset;
  delete entry.displayName;
  delete entry.uniqueId;
  if (!manualTransferTypeRef[entry.type]) {
    delete entry.contract;
  }
  if (!manualDefiTypeRef[entry.type]) {
    delete entry.chain;
  }
};

const deleteCryptoFields = (entry = {}) => {
  delete entry.toSymbol;
  delete entry.fromSymbol;
  delete entry.toAmount;
  delete entry.fromAmount;
};

const deleteFeeFields = (entry = {}) => {
  delete entry.fee;
  delete entry.feeCurrency;
  delete entry.feeCurrencyContract;
};

const deleteIdFields = (entry = {}) => {
  if (checkInvalidInput(entry.id)) delete entry.id;
};

const deleteOrganizationFields = (entry = {}) => {
  if (!entry.taxCategory || entry.taxCategory !== 'donation_standard') {
    delete entry.organizationId;
  }
  delete entry.organizationName;
  delete entry.organizationAddress;
};

const formatEntryDateTime = (entry = {}) => {
  // date & time
  const { date } = entry;

  let newDate = getDate(date, {
    timezone: entry.timezone,
    timezoneOnly: true,
  }).startOf('day');

  if (!checkInvalidInput(entry.time)) {
    const { hour, minute } = getDate(entry.time);
    newDate = newDate.set({ hour, minute });
  }
  return newDate.toUTC().toFormat('MM/dd/yyyy HH:mm');
};

const handleEntryFees = (entry = {}) => {
  /**
   * Conditions that can delete fee & feeCurrency:
   * if no fee is found
   * type is transfer-in
   * type is a wallet type but include a fiat fee currency (unless NFT or DeFi)
   * type is a wallet type but does not include a fee currency
   */
  if (
    checkInvalidInput(entry.fee) ||
    entry.type === manualTransferTypeRef.transferIn ||
    ((checkInvalidInput(entry.feeCurrency) ||
      g20CurrencyNames[entry.feeCurrency]) &&
      !manualOrderTypeRef[entry.type] &&
      !manualNFTTypeRef[entry.type] &&
      !manualDefiTypeRef[entry.type])
  ) {
    deleteFeeFields(entry);
  } else {
    // save as string
    entry.fee = `${entry.fee}`;
  }
};

const handleTransactionFeeType = (entry = {}) => {
  entry.amount = '0';
  entry.price = 'spot';
  entry.symbol = entry.feeCurrency;
  entry.type = manualTransferTypeRef.transferOut;
}

/**
 * STANDARD MANUAL FORMATTING
 */
const formatStandardEntries = (exchangeName, entries) =>
  entries.map((entry) => {
    // date
    entry.date = formatEntryDateTime(entry);

    // add exchange name as source
    if (exchangeName) entry.source = exchangeName;
    else delete entry.source;

    // taxCategory
    if (checkInvalidInput(entry.taxCategory) || g20CurrencyNames[entry.symbol]) delete entry.taxCategory;

    // price setting
    if (checkInvalidInput(entry.price) || entry.useDefaultPricing === 'true') entry.price = 'spot';

    // price currency setting
    if (entry.priceCurrency === '') entry.priceCurrency = 'USD';

    // converting floats to strings
    entry.price = `${entry.price}`;
    entry.amount = `${
      !manualOrderTypeRef[entry.type]
        ? evaluateZero(entry.amount)
        : entry.amount
    }`;

    // fee setting
    handleEntryFees(entry);

    // contract address currency
    if (checkInvalidInput(entry.contract)) delete entry.contract;

    // transaction fee
    if (entry.taxCategory === manualUIStandardCategories[0].dbValue)
      handleTransactionFeeType(entry);

    // delete extraneous fields
    deleteManualUIFields(entry);
    deleteCryptoFields(entry);
    deleteDefiFields(entry);
    deleteNFTFields(entry);
    deleteIdFields(entry);
    deleteOrganizationFields(entry);

    // return formatted entry
    return entry;
  });

/**
 * MANUAL CRYPTO-CRYPTO FORMATTING
 */
const formatCryptoEntries = (exchangeName, entries = []) =>
  entries.map((entry) => {
    // date
    entry.date = formatEntryDateTime(entry);
    // type
    entry.type = 'crypto-crypto';
    // add exchange name as source
    if (exchangeName) entry.source = exchangeName;
    else delete entry.source;
    // id
    if (checkInvalidInput(entry.id)) delete entry.id;
    // price
    const adjustPricing = entry.priceCurrency === entry.toSymbol;
    entry.price = `${
      parseFloat(adjustPricing ? entry.toAmount : entry.fromAmount) /
      parseFloat(adjustPricing ? entry.fromAmount :entry.toAmount)
    }`;
    // amounts
    entry.toAmount = `${entry.toAmount}`;
    entry.fromAmount = `${entry.fromAmount}`;
    // fee setting
    handleEntryFees(entry);

    // delete
    delete entry.taxCategory;
    deleteManualUIFields(entry);
    deleteNormFields(entry);
    deleteDefiFields(entry);
    deleteNFTFields(entry);
    deleteIdFields(entry);
    deleteOrganizationFields(entry);
    return entry;
  });

/**
 * MANUAL DEFI FORMATTING
 */
const formatDefiEntries = (entries = []) =>
  entries.map((entry) => {
    // date
    entry.date = formatEntryDateTime(entry);
    // amount
    entry.amount1 = `${evaluateZero(entry.amount1)}`;
    // fees
    handleEntryFees(entry);
    // delete fields
    delete entry.price;
    delete entry.priceCurrency;
    delete entry.source;
    deleteManualUIFields(entry);
    deleteNFTFields(entry);
    deleteNormFields(entry);
    deleteCryptoFields(entry);
    deleteOrganizationFields(entry);
    if (checkInvalidInput(entry.poolShare)) {
      delete entry.poolShare;
    } else {
      entry.poolShare = `${entry.poolShare}`;
    }

    if (checkInvalidInput(entry.id)) delete entry.id;

    if (!manualDefiFormatTypeRef[entry.type]) {
      delete entry.asset2;
      delete entry.amount2;
      delete entry.asset3;
      delete entry.amount3;
      delete entry.asset4;
      delete entry.amount4;
      delete entry.asset2Contract;
    } else {
      entry.amount2 = `${
        manualDefiTypeRef.swap !== entry.type ? entry.amount2 : -entry.amount2
      }`;

      if (
        !checkInvalidInput(entry.asset3) &&
        !checkInvalidInput(entry.amount3)
      ) {
        entry.amount3 = `${entry.amount3}`;
      } else {
        delete entry.asset3;
        delete entry.amount3;
      }

      if (
        !checkInvalidInput(entry.asset4) &&
        !checkInvalidInput(entry.amount4)
      ) {
        entry.amount4 = `${entry.amount4}`;
      } else {
        delete entry.asset4;
        delete entry.amount4;
      }
    }
    if (checkInvalidInput(entry.defiSource)) entry.defiSource = 'generic';
    delete entry.taxCategory;
    deleteIdFields(entry);
    // return formatted entry
    return entry;
  });

/**
 * MANUAL NFT FORMATTING
 */
const formatNFTEntries = (entries = []) =>
  entries.map((entry) => {
    // date
    entry.date = formatEntryDateTime(entry);
    // fees
    handleEntryFees(entry);
    // delete fields
    delete entry.source;
    delete entry.taxCategory;
    deleteManualUIFields(entry);
    deleteCryptoFields(entry);
    deleteDefiFields(entry);
    deleteNormFields(entry);
    deleteIdFields(entry);
    deleteOrganizationFields(entry);
    return entry;
  });

export const formatEntriesForSubmission = ({
  entries,
  exchangeName,
  formatterType = 'standard',
}) => {
  if (formatterType === 'crypto')
    return formatCryptoEntries(exchangeName, entries);
  if (formatterType === 'defi') return formatDefiEntries(entries);
  if (formatterType === 'nft') return formatNFTEntries(entries);
  return formatStandardEntries(exchangeName, entries);
};

/**
 * MANUAL HELPERS
 */
export const convertEntryValuesToString = (entries) =>
  entries.map((entry) => {
    if (typeof entry.toAmount === 'number')
      entry.toAmount = `${entry.toAmount}`;
    if (typeof entry.fromAmount === 'number')
      entry.fromAmount = `${entry.fromAmount}`;
    if (typeof entry.price === 'number') entry.price = `${entry.price}`;
    if (typeof entry.amount === 'number') entry.amount = `${entry.amount}`;
    if (typeof entry.fee === 'number') entry.fee = `${entry.fee}`;
    return entry;
  });

export const toTypes = [
  manualOrderTypeRef.buy,
  manualOrderTypeRef.deposit,
  manualTransferTypeRef.transferIn,
];
export const fromTypes = [
  manualOrderTypeRef.sell,
  manualOrderTypeRef.withdrawal,
  manualTransferTypeRef.transferOut,
];

export const checkDefaultTime = (currentEntry = {}) => {
  if (currentEntry.date) {
    const dateObj = getDate(new Date(currentEntry.date), {
      parseMethod: 'JSDate',
      timezoneOnly: true,
    });

    return dateObj;
  }
  return null;
};

export const checkDefaultPrice = (currentEntry) => {
  const { type } = currentEntry;
  if (type !== null && currentEntry.price) {
    return currentEntry.price.toString();
  }
  return null;
};

export const checkDefaultFee = (currentEntry) =>
  !!(currentEntry && currentEntry.fee && currentEntry.feeCurrency);

export const getDefaultEntryForm = (type) => {
  if (manualDefiTypeRef[type]) return 'defi';
  if (manualNFTTypeRef[type]) return 'nft';
  if (manualOrderTypeRef.crypto === type) return 'crypto';
  return 'standard';
};

export const handleManualDateDisplay = (date) => {
  const displayDate = getDate(date).toFormat('MM/dd/yyyy');
  return displayDate;
};

export const handleManualTimeDisplay = (time) => {
  const timeDisplay = convertToStandardTime(time);
  if (timeDisplay === 'Invalid DateTime') return '12:00 AM';
  return timeDisplay;
};

export const createEntryFromReviewTx = ({
  currencyMap,
  originalTx,
  reviewTx,
}) => {
const isNFT = !!originalTx.nft || originalTx.context?.subtype === 'nft';
const context = isNFT ? 'nft' : 'standard';

const date = reviewTx?.timestamp
  ? getDate(new Date(reviewTx.timestamp), {
      parseMethod: 'JSDate',
    })
  : null;

const sourceType = Object.keys(reviewTx).length > 0 && isNFT ? 'wallet' : null;
const amount = reviewTx.amount ? reviewTx.amount.toString() : null;
const symbol = reviewTx.currency || null;
const contract = originalTx.nft?.contractAddress || originalTx.context?.contractAddress || null;
const uniqueId =
  originalTx.nft?.tokenId || originalTx.context?.tokenId || null;

  return {
    ...getDefaultValues(),
    context,
    sourceType,
    amount,
    date,
    time: checkDefaultTime({ date: reviewTx.timestamp || null }),
    symbol,
    useDefaultPricing: `${!!currencyMap?.[symbol]}`,
    contract,
    uniqueId,
  };
};

export const getFormatterType = (entry) => {
    const { context, orderType } = entry || {};
    return orderType === 'crypto' ? 'crypto' : context;
};

export const getAssetType = (entry) => {
  const {  context } = entry || {};
  if (['defi', 'nft'].includes(context)) return context;
  return 'regular'
};

// exporting here for testing
export const utilities = {
  checkInvalidInput,
};
