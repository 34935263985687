// absolute imports
import React, { useMemo, useState, useCallback, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Form, } from 'react-final-form';
import { useReactiveVar } from '@apollo/client';
import { FORM_ERROR } from 'final-form';

// relative imports
import {
  formStateVar,
} from '../../../data/apollo/cache/reactiveVars';
import FormPristineSynchronizer from '../final-form-field-wrappers/FormPristineSynchronizer';
import Loader from './Loader';

  /**
   * {}
   * valid options
   * submissions: {} - custom submission to perform for a specific page
   * validators: {} - custom validation to perform for a specific page
   * suspensions: {} - indicates the page already is wrapped with a Suspense component
   * and does not require a defaulted one
   */
  const customPageFormOptions = {
    submissions: {},
    validators: {},
    suspensions: {},
  };

    /**
   * {} - options param
   * valid options
   * customPage: number - takes number to set for page if should skip some pages
   */

  const defaultOptions = {
    customPage: null,
    setCustomPage: null,
  };

  const getNewPage = (options = defaultOptions, pageIndex, pageCount) => {
    const { customPage, setCustomPage } = options;

    if (setCustomPage) return setCustomPage(pageCount, pageIndex);
    if (customPage || customPage === 0) return customPage;
    return pageIndex;
  };

const MultiPageForm = ({
  lazy,
  persist,
  children,
  goToPage,
  validate,
  formSubmit,
  formOptions,
  formPageOptions,
  setGoToPage,
  renderHeader,
  initialValues,
  excludedFields,
  submitError,
  ...extraProps
}) => {
  const formState = useReactiveVar(formStateVar);
  const [page, setPage] = useState(goToPage);
  const [values, setValues] = useState(initialValues);
  const [pageOptions, setPageOptions] = useState(formPageOptions);

  const formChildren = useMemo(() => React.Children.toArray(children), [children]);

  const pageCount = formChildren.length - 1;
  const isLastPage = page === pageCount;
  const activePage = formChildren[page];

  const nextPage = useCallback(
    (vals, options = defaultOptions) => {
      const pageIndex = Math.min(page + 1, pageCount);
      const newPage = getNewPage(options, pageIndex, pageCount);

      setPage(newPage);

      if (setGoToPage) {
        setGoToPage(newPage);
      }
      setValues(vals);
    },
    [page, pageCount, setGoToPage],
  );

  const previousPage = useCallback(
    (options = defaultOptions) => {
      const pageIndex = Math.max(page - 1, 0);
      const newPage = getNewPage(options, pageIndex, pageCount);
      setPage(newPage);

      if (setGoToPage) {
        setGoToPage(newPage);
      }
    },
    [page, pageCount, setGoToPage],
  );

  const updatePageOptions = useCallback((updatedOptions) => {
    setPageOptions({
      submissions: {
        ...(formPageOptions?.submissions || {}),
        ...(updatedOptions?.submissions || {}),
      },
      validators: {
        ...(formPageOptions?.validators || {}),
        ...(updatedOptions?.validators || {}),
      },
      suspensions: {
        ...(formPageOptions?.suspensions || {}),
        ...(updatedOptions?.suspensions || {}),
      },
    });
  }, [formPageOptions, setPageOptions]);

  const handleValidation = async (vals) => {
    const currentPageValidate = formPageOptions?.validators?.[page];

    if (currentPageValidate) return currentPageValidate(vals);
    if (validate) return validate(vals);
    return {};
  };

const handlePageSubmit = async (vals) => {
  const submissionValues = persist ? { ...formState, ...vals } : vals;

  if (persist && excludedFields?.length) {
    excludedFields.forEach((excludeField) => {
      delete submissionValues[excludeField];
    });
  }

  if (persist) {
    formStateVar(submissionValues);
  }

  if (isLastPage) {
    if (!persist) formStateVar({});
    return formSubmit(submissionValues);
  }

  const currentPageSubmit = pageOptions?.submissions?.[page];

  if (currentPageSubmit) {
    try {
      return currentPageSubmit(vals, (currentPageOptions) => {
        nextPage(vals, {
          ...defaultOptions,
          ...currentPageOptions,
        });
      });
    } catch (error) {
      return {
        [FORM_ERROR]: error.message || 'An error occurred during page submission.',
      };
    }
  }
  return nextPage(vals);
};

  const getActivePageComponent = useCallback(
    (pageProps) => {
      const newActivePage = React.cloneElement(activePage, {
        nextPage,
        previousPage,
        setPage,
        setValues,
        currentPage: page,
        setFormState: formStateVar,
        updatePageOptions,
        ...pageProps,
        ...extraProps,
      });

      if (!lazy || formPageOptions?.suspensions?.[page]) return newActivePage;

      return (
        <Suspense fallback={<Loader fillSpace />}>{newActivePage}</Suspense>
      );
    },
    [
      activePage,
      nextPage,
      setPage,
      page,
      setValues,
      previousPage,
      extraProps,
      lazy,
      formPageOptions,
      updatePageOptions,
    ],
  );

  return (
    <>
      {renderHeader && renderHeader({ values, page, setPage })}
      <Form
        initialValues={values}
        validate={!handleValidation}
        onSubmit={handlePageSubmit}
        {...formOptions}
      >
        {({ handleSubmit, pristine, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <FormPristineSynchronizer
              pristine={
                page === 0 || (!submitError && isLastPage) ? pristine : false
              }
              submitError={submitError}
            />
            {getActivePageComponent({ pristine, ...rest })}
          </form>
        )}
      </Form>
    </>
  );
};

export default MultiPageForm;

MultiPageForm.propTypes = {
  lazy: PropTypes.bool,
  persist: PropTypes.bool,
  validate: PropTypes.func,
  goToPage: PropTypes.number,
  setGoToPage: PropTypes.func,
  renderHeader: PropTypes.func,
  formOptions: PropTypes.shape(),
  formPageOptions: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formPageSuspensions: PropTypes.shape(),
  formPageSubmissions: PropTypes.shape(),
  formPageValidations: PropTypes.shape(),
  excludedFields: PropTypes.arrayOf(PropTypes.string),
  submitError: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.bool,
  ]),
};

MultiPageForm.defaultProps = {
  goToPage: 0,
  lazy: false,
  persist: false,
  excludedFields: null,
  validate: () => {},
  formOptions: {},
  formPageOptions: customPageFormOptions,
  setGoToPage: null,
  initialValues: {},
  renderHeader: null,
  formPageSuspensions: null,
  formPageSubmissions: null,
  formPageValidations: null,
  submitError: null,
};
