// relative imports
import {
  offsetLimitPagination,
  relayStylePagination,
} from '@apollo/client/utilities';
import {
  isEpochStart,
  getDate,
  getDateFromFormats,
} from '../../../components/shared/utilities/dateUtilities';
import {
  currentAccountIdVar,
  accountingTableStateVar,
  formStateVar,
} from './reactiveVars';

const handleTypePolicyDates = (existing, format) => {
  let existingDate;
  if (existing) existingDate = getDateFromFormats(existing, [format]);
  if (!existing || existingDate.invalid)
    return getDate(existing).toFormat(format);
  return existing;
};

/**
 * Type Policies - Define any client-side cache logic
 * for client or server schema types
 */
export default {
  Query: {
    fields: {
      currentAccountId: {
        read() {
          return currentAccountIdVar();
        },
      },
      accountingTableState: {
        read(_, { variables }) {
          return (
            accountingTableStateVar()?.[variables.accountId] ||
            accountingTableStateVar() ||
            {}
          );
        },
      },
      formState: {
        read() {
          return formStateVar();
        },
      },
      getAccounts: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      wallets: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      getWalletsUpdating: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      getIncomeBreakdown: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      getGainLossPerYear: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      getPaginatedTxesForTax: relayStylePagination(),
      getExchangesUpdating: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      getReportsByFilter: {
        keyArgs: [
          'accountId',
          'filters',
          ['id', 'value', 'range'],
          'clientAccountName',
          'sortBy',
          ['id', 'desc'],
        ],
        merge: true,
      },
      getPreparerLinksByFilter: {
        keyArgs: [
          'accountId',
          'filters',
          ['id', 'arrayValue', 'value'],
          'clientAccountName',
          'sortBy',
          ['id', 'desc'],
        ],
        merge: true,
      },
      getCurrencies: {
        keyArgs: ['accountId', 'filter'],
        merge(existing, incoming) {
          return incoming;
        },
      },
      walletsSummary: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      eSourceSummary: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  AccountingIntegration: {
    fields: {
      lastSyncedWallet: {
        read(existing) {
          if (isEpochStart(existing)) {
            return null;
          }
          return handleTypePolicyDates(existing, 'MMM d, yyyy, h:mm a');
        },
      },
      lastSyncedExchange: {
        read(existing) {
          if (isEpochStart(existing)) {
            return null;
          }
          return handleTypePolicyDates(existing, 'MMM d, yyyy, h:mm a');
        },
      },
    },
  },
  AccountSubtypeFields: {
    merge(existing, incoming) {
      return incoming;
    },
  },
  Balance: {
    fields: {
      balances: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  BalanceAudit: {
    fields: {
      timestamp: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MM/d/yy');
        },
      },
    },
  },
  BalanceInstance: {
    merge: true,
    keyFields: ['currency', 'amount'],
  },
  Order: {
    merge: true,
    fields: {
      Timestamp: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MMM d, yyyy, h:mm a');
        },
      },
    },
  },
  Transaction: {
    merge: true,
    fields: {
      Timestamp: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MMM d, yyyy, h:mm a');
        },
      },
    },
  },
  Report: {
    fields: {
      requested: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy, h:mm a');
        },
      },
      parameters: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  LiquidityPoolMovement: {
    fields: {
      timestamp: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MMM d, yyyy, h:mm a');
        },
      },
    },
  },
  LiquidityPoolTransactions: {
    fields: {
      fromDate: {
        read(existing) {
          return getDate(existing).toString();
        },
      },
      toDate: {
        read(existing) {
          return getDate(existing).toString();
        },
      },
    },
  },
  TaxTx: {
    fields: {
      timestamp: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy, h:mm a');
        },
      },
      reconciliations: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  TaxTxFilter: {
    fields: {
      startTime: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy t');
        },
      },
      endTime: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/YYYY t');
        },
      },
    },
  },
  TaxPreparerLink: {
    fields: {
      createdAt: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy');
        },
      },
      codeLastSent: {
        read(existing) {
          if (existing) return handleTypePolicyDates(existing, 'M/d/yyyy');
          return null;
        },
      },
    },
  },
  TmpUser: {
    keyFields: ['email'],
    fields: {
      validTill: {
        read(existing) {
          return getDate(existing).toString();
        },
      },
    },
  },
  TaxReconciliation: {
    merge: true,
    fields: {
      timestamp: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy, h:mm a');
        },
      },
    },
  },
  ESource: {
    fields: {
      lastUpdated: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy, h:mm a');
        },
      },
      orders: {
        merge(existing, incoming = []) {
          return incoming;
        },
      },
    },
  },
  Wallet: {
    fields: {
      addresses: offsetLimitPagination(),
      lastUpdated: {
        read(existing) {
          return handleTypePolicyDates(existing, 'M/d/yyyy, h:mm a');
        },
      },
    },
    transactions: {
      merge(existing, incoming = []) {
        return incoming;
      },
    },
  },
  UsagePlan: {
    merge: true,
  },
  UserProfile: {
    keyFields: ['email'],
    merge: true,
  },
  UserPermission: {
    keyFields: ['accountId', 'userId'],
  },
  AccountStatistics: {
    merge: true,
  },
  TaxUpdater: {
    merge: true,
  },
  AddedUser: {
    keyFields: ['email'],
  },
  TaxAddons: {
    merge: true,
  },
  LiquidityPool: {
    keyFields: ['address', 'poolAddress'],
    merge: true,
  },
  ESourceListItem: {
    keyFields: ['name'],
  },
  Chain: {
    keyFields: ['symbol'],
  },
  SummaryReport: {
    fields: {
      assets: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Summary: {
    fields: {
      assets: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  TxTableRow: {
    merge: true,
    fields: {
      txDate: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MMM d, yyyy, h:mm a');
        },
      },
      memos: {
        merge(existing = [], incoming) {
          return incoming || existing;
        },
      },
    },
  },
  IncomeBreakdown: {
    merge: true,
  },
  YearlyGainLoss: {
    merge: true,
  },
  CustomInoutData: {
    merge: true,
  },
  NFT: {
    fields: {
      acquisitionDate: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MM/dd/yyyy');
        },
      },
    },
  },
  TaxBillingDetails: {
    keyFields: ['accountId'],
  },
  PaymentHistory: {
    merge: true,
  },
  FeedConnection: {
    fields: {
      edges: {
        merge(existing = [], incoming = [], { readField, variables } = {}) {
          const { feedType } = variables;

          // skip merge for TAXTX type as there is no persistence of multiple pages
          if (feedType === 'TAXTX') {
            return [...incoming];
          }
          // else use unique set logic
          const edgesRef = existing?.reduce((edgeRef, item) => {
            edgeRef[readField('cursor', item)] = item;
            return edgeRef;
          }, {});

          incoming.forEach((item) => {
            const key = readField('cursor', item);
            if (key) edgesRef[key] = item;
          });

          return Object.values(edgesRef);
        },
      },
    },
  },
  Logs: {
    logs: offsetLimitPagination(),
  },
  NftCostBasisBreakdown: {
    fields: {
      acquisitionDate: {
        read(existing) {
          return handleTypePolicyDates(existing, 'MM/dd/yyyy');
        },
      },
    },
  },
  UserControl: {
    keyFields: false,
    merge: true,
  },
  UserChanges: {
    keyFields: false,
    merge: true,
  },
  UserMetadata: {
    keyFields: false,
    merge: true,
  },
  TaxTxContext: {
    keyFields: false,
    merge: true,
  },
};
